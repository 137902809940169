import { html, nothing, render } from "lit-html";
import { classMap } from "lit-html/directives/class-map.js";
import { translate } from "@escapio/shared/l10n/translate";
import { onChangeCurrency } from "@escapio/portal/state/travelData";
import { matchesCurrency } from "@escapio/portal/components/deals/matches-currency";
import { track, trackPageOnce } from "@escapio/portal/tracker/EventTracker";
import { addEventTrackerData } from "@escapio/portal/tracker/EventQueue";
import { buildClickProfileLabel } from "@escapio/portal/components/deals/track";
import { EVENT_ACTION_PREFIX as EVENT_ACTION_PREFIX_HOTEL } from "@escapio/portal/components/deals/Hotel";
import {
	EVENT_ACTION_PREFIX as EVENT_ACTION_PREFIX_ARTICLE,
	getMobileDealStageTemplate,
} from "@escapio/portal/components/deals/Article";
import {
	dealTemplate,
	formatDealDateRange,
} from "@escapio/portal/components/deals/get-description-template";
import {
	isAtLeast,
	SIZE_DESKTOP,
	unwatch,
	watch,
} from "@escapio/portal/dom/screen";
import "@escapio/portal/components/HotelFavoriteStatus";
import "@escapio/portal/components/hotel-list/hotel-element/MobileDescription";
import "@escapio/portal/components/VisibilityTracker";

const EVENT_CATEGORY = "Home";

customElements.define(
	"esc-featured-deals",
	class extends HTMLElement {
		connectedCallback() {
			this.render();

			const content = this.querySelector(".featured-deals"),
				wrapper = this.querySelector(".scroll-wrapper"),
				shadowLeft = this.querySelector(".shadow--left"),
				shadowRight = this.querySelector(".shadow--right");

			const updateShadows = () => {
				const contentScrollWidth = content.scrollWidth - wrapper.offsetWidth;
				if (!contentScrollWidth) {
					shadowLeft.style.opacity = 0;
					shadowRight.style.opacity = 0;
					return;
				}
				const currentScroll = content.scrollLeft / contentScrollWidth;
				shadowLeft.style.opacity = currentScroll;
				shadowRight.style.opacity = 1 - currentScroll;
			};

			onChangeCurrency(() => this.render());
			watch(this, () => {
				this.render();
				updateShadows();
			});
			content.addEventListener("scroll", () => {
				trackPageOnce(EVENT_CATEGORY, "Deals", "Swipe");
				updateShadows();
			});
			updateShadows();
		}

		disconnectedCallback() {
			unwatch(this);
		}

		render() {
			const numDealsShown = isAtLeast(SIZE_DESKTOP) ? 3 : 4;
			const dealCandidates = this.deals.filter(matchesCurrency);
			const deals = dealCandidates.slice(0, numDealsShown);
			this.style.setProperty("--num-deals-shown", `${numDealsShown}`);

			if (deals.length < numDealsShown) {
				this.style.display = "none";
			} else {
				this.style.display = "";
			}

			const template = deals.map((deal, index) =>
				deal.hotel
					? this.renderHotelDeal(deal, index + 1)
					: this.renderArticleDeal(deal, index + 1),
			);
			render(
				html`<section>
					<header>
						<h1 class="-home__featured__header">
							${translate("home_featured_deals_header")}
						</h1>
					</header>

					<div class="scroll-wrapper">
						<div class="shadow shadow--left"></div>
						<div class="shadow shadow--right"></div>
						<div class="featured-deals">${template}</div>
					</div>
					<esc-click-tracker
						category="${EVENT_CATEGORY}"
						action="Deals"
						label="Alle anzeigen"
						use-event-queue
					>
						<a
							href="${this.dealsPageUrl}"
							class="featured-deals__button inverted-action-button ${classMap({
								hidden: dealCandidates.length <= numDealsShown,
							})}"
						>
							${translate("home_deals_show_all")}
						</a>
					</esc-click-tracker>
				</section>`,
				this,
				{
					host: this,
				},
			);
		}

		renderHotelDeal(deal, position) {
			const dates = formatDealDateRange(deal);
			return html`<esc-visibility-tracker
				class="featured-deal"
				category="${EVENT_CATEGORY}"
				action="${EVENT_ACTION_PREFIX_HOTEL} angezeigt - ${deal.hotel
					.id} - ${dates}"
				label="${position}"
			>
				<a
					class="hotel-list-item-mobile__picture-link"
					href="${deal.hotel.path}"
					@click="${this.trackProfileClick(deal, "Bild (1)", position)}"
				>
					<esc-picture
						alt="${deal.hotel.image.alt}"
						type="hotel"
						filename="${deal.hotel.image.filename}"
						sizes="mobile:568x284"
					></esc-picture>
					${deal.hotel.review_score > 0
						? html` <div class="hotel-satisfaction is-translucent">
								<span class="fa fa-thumbs-o-up fa--space-after"></span>
								${deal.hotel.review_score}%
							</div>`
						: nothing}
					<esc-hotel-favorite-status
						class="hotel-list-item-mobile__favorite is-translucent"
						hotel-id=${deal.hotel.id}
						hotel-name=${deal.hotel.name}
						@addToFavorites="${this.onAddToFavorites(deal, position)}"
						track-action-suffix="${this.getAttribute(
							"favorites-track-action-suffix",
						)}"
						only-icon
					>
					</esc-hotel-favorite-status>
				</a>

				<a href=${deal.hotel.path}>
					<esc-hotel-list-hotel-description-mobile
						.hotel=${deal.hotel}
						@click="${this.trackProfileClick(deal, "Hotelname", position)}"
					></esc-hotel-list-hotel-description-mobile>
				</a>

				<div class="featured-deal__container">
					${dealTemplate(
						translate("deals_list_cta_label_hotel"),
						deal,
						EVENT_CATEGORY,
						`${EVENT_ACTION_PREFIX_HOTEL} - Deal geklickt - ${deal.hotel.id} - ${dates}`,
						position,
					)}
				</div>
			</esc-visibility-tracker>`;
		}

		renderArticleDeal(deal, position) {
			return html`<esc-visibility-tracker
				class="featured-deal"
				category="${EVENT_CATEGORY}"
				action="${EVENT_ACTION_PREFIX_ARTICLE} angezeigt - ${deal.headline} - ${formatDealDateRange(
					deal,
				)}"
				label="${position}"
			>
				${getMobileDealStageTemplate(deal)}
				<div class="featured-deal__container">
					${dealTemplate(
						translate("deals_list_cta_label_article"),
						deal,
						EVENT_CATEGORY,
						`${EVENT_ACTION_PREFIX_ARTICLE} - Deal geklickt - ${deal.headline}`,
						position,
					)}
				</div>
			</esc-visibility-tracker>`;
		}

		onAddToFavorites(deal, position) {
			return () =>
				track(
					EVENT_CATEGORY,
					`${EVENT_ACTION_PREFIX_HOTEL} - Merken - ${deal.hotel.id} - ${formatDealDateRange(
						deal,
					)}`,
					position,
				);
		}

		trackProfileClick(deal, context, position) {
			return () =>
				addEventTrackerData(
					EVENT_CATEGORY,
					buildClickProfileLabel(EVENT_ACTION_PREFIX_HOTEL, context, deal),
					position,
				);
		}

		get deals() {
			return JSON.parse(this.getAttribute("deals")) || [];
		}

		get dealsPageUrl() {
			return this.getAttribute("deals-page-url");
		}
	},
);
